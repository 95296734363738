<template>
  <div class="w-full flex flex-col md:w-auto md:bg-transparent md:ml-auto">
    <int-search-bar
      id="headerSearch"
      v-model:search-param="keyword"
      class="my-auto bg-transparent border-transparent"
      button-text="Search courses"
      placeholder-text="Search TAFE NSW International"
      @search="navigateToCourseSearch"
    />
  </div>
</template>

<script setup lang="ts">
import { useIntSiteConfigStore } from '~~/stores/intSiteConfig'

const keyword = ref('')
const router = useRouter()
const intSiteConfigStore = useIntSiteConfigStore()

// Computed
const featureFlags = computed(() => {
  return intSiteConfigStore.config?.featureFlags
})

// ToDo: review if this FF is still in use
/* logically currently it is not used as planned, but there is another usage
 * https://tafensw.atlassian.net/browse/TNW-5561
 * */
const hideIntSearch = computed(() => {
  return featureFlags.value?.hideInternationalHomepageSearch
})

// Methods
const navigateToCourseSearch = (keyword: string) => {
  // Bring user to Liferay international course search page if 'hideInternationalHomepageSearch' feature flag turn on
  if (hideIntSearch.value) {
    navigateTo(
      `https://www.tafensw.edu.au/international/course-search-results?k=${keyword}&s=0&c=0&q=0`,
      {
        external: true
      }
    )
  } else {
    router.push({ path: '/international/course-search', query: { keyword } })
  }
}
</script>

<style lang="postcss">
#headerSearch {
  @apply bg-transparent border-transparent;
}

@media (max-width: 767px) {
  #headerSearch {
    @apply bg-int-grey-02 pl-12 placeholder-int-off-white;
  }
  #headerSearch,
  #headerSearch + label .tf-icon,
  #headerSearch + label + button .tf-icon {
    @apply text-white;
  }

  #headerSearch {
    @apply pl-12 placeholder-int-off-white;
  }
}

@media (min-width: 768px) {
  #headerSearch {
    @apply pl-0 pr-0 text-transparent	w-12 indent-24;
    /* TODO: tweak transitions esp. bg-color */
    transition: all 0.36s;
  }

  #headerSearch:focus {
    @apply bg-white text-black pl-12 indent-0;
    border-color: #3182ce;
    width: 40vw;
  }

  #headerSearch + label {
    @apply text-white;
  }

  #headerSearch:focus + label {
    @apply text-black;
  }

  #headerSearch + label + button {
    display: none;
  }

  #headerSearch:focus + label + button {
    display: block !important;
  }
}
</style>
